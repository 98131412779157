import React from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from '../assets/logoWhiteText.svg';
import { ReactComponent as WaLogo } from '../assets/whatsapp.svg';
import { ReactComponent as PhoneLogo } from '../assets/phone.svg';
import { ReactComponent as MailLogo } from '../assets/mail.svg';

function Footer() {
  return (
    <footer
      style={{
        color: '#ffffff',
        padding: '20px 0',
        position: 'sticky',
        minHeight: 300,
        maxHeight: 'fit-content',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 45,
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginTop: 10,
              paddingTop: 40,
              gap: 20,
            }}
          >
            <div className="footer-column">
              <div className="footer-logo">
                <Logo style={{ height: 70, width: 165 }} />
              </div>
            </div>
            <div className="footer-column">
              <h3 className="mb-3">Address</h3>
              <div>Touwslager 1</div>
              <div>3861 SP Nijkerk</div>
              <div>The Netherlands</div>
            </div>
            <div className="footer-column">
              <h3>Contact us</h3>
              <div className="mt-2" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <PhoneLogo className="text-icon-left" />
                +31 85 109 1128
              </div>
              <div className="mt-2" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <WaLogo className="text-icon-left" />
                +31 85 109 1128
              </div>
              <div className="mt-2" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <MailLogo className="text-icon-left" />
                <a
                  href="mailto:sales@transportinghighway.com"
                  rel="noreferrer"
                  target="_blank"
                  role="button"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  info@transportinghighway.com
                </a>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 11,
                fontSize: '12px',
              }}
            >
              <span className="default-color">
                © 2024 Transporting Highway. All rights reserved.
              </span>
              <a
                href="https://transportinghighway.com/privacy-statement/"
                rel="noreferrer"
                target="_blank"
                role="button"
                style={{ textDecoration: 'none' }}
                className="link-grey"
              >
                Privacy Statement
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
